import React, { useState } from 'react'
import { Link } from 'gatsby'

import img1 from '../../assets/images/img-details-1.png'
import img2 from '../../assets/images/img-details-2.png'
import img3 from '../../assets/images/img-details-3.png'
import img4 from '../../assets/images/img-details-4.png'
import img5 from '../../assets/images/img-details-5.png'
import img6 from '../../assets/images/img-details-6.png'
import img7 from '../../assets/images/img-details-7.png'
import img8 from '../../assets/images/img-details-8.png'
import img9 from '../../assets/images/img-details-9.png'

import imgTeamContact from '../../assets/images/img-team-2.png'

import SEO from '../../components/SEO'
import Header from '../../components/Header'
import TagsMarquee from '../../components/TagsMarquee'
import MintButtons from '../../components/MintButtons'
import ShareButtons from '../../components/ShareButtons'

import { theme, colors } from '../../constants/theme'

import {
  ProjectDetailsLayout,
  SectionCover,
  SectionIntro,
  SectionRichText,
  SectionCounterImage,
  SectionCounterGrid,
  SectionFullImage,
  SectionImagesGrid,
  SectionFooter,
  SectionNavigator,
  StickyButtons,
  Square,
} from '../../styles/ProjectDetailsLayout'
import { Content } from '../../styles/PageLayout'
import {
  HeadlineL,
  HeadlineM,
  TitleL,
  TitleM,
  SubtitleM,
  ParagraphL,
  ParagraphM,
  ParagraphS,
} from '../../styles/Typography'

/**
 * A project details template, to be used in gatsby-node.
 * Includes sections that can be conditionally rendered.
 * @returns {StatelessComponent}
 */
const ProjectDetails = () => {
  const [selected, setSelected] = useState(0)

  const tags = [
    'Vodenje družbenih omrežij',
    'Fotografija',
    'Video produkcija',
    'Spletne strani',
    'Spletne trgovine',
    'Organizacija dogodkov',
    'Google oglasi',
    'Oglaševanje na družbenih omrežjih',
    'Grafično oblikovanje',
    'UI',
    'UX',
    'Znamčenje',
    'Tekstopisje',
  ]

  return (
    <>
      <SEO
        title="Mint and Square | Project Name"
        description="Mladim starih med 15 in 30 let predstaviti model participatornega proračuna in jih spodbuditi k vključitvi v projekt."
        image={img1}
      />
      <Header
        logoBackground={colors.purple}
        logoBorder={colors.purple}
        linkText={colors.purple}
        menuLines={colors.purple}
      />

      <ProjectDetailsLayout>
        {/* Cover Section - Aways visible */}
        <SectionCover>
          <div className="cover-image">
            <img src={img1} alt="Project name" />
          </div>
          <Square background={colors.red} className="title">
            <ParagraphM color={colors.white}>Občina Tolmin</ParagraphM>
            <HeadlineM color={colors.purple}>Mlada Pobuda</HeadlineM>
          </Square>
          <div className="tags">
            <TagsMarquee tags={tags} />
          </div>
        </SectionCover>

        {/* Intro Section - Aways visible */}
        <SectionIntro background={colors.purple}>
          <Content>
            <TitleM color={colors.white}>Izziv</TitleM>
            <ParagraphL color={colors.white} weight="300">
              <b>Mladim starih med 15 in 30 let</b> predstaviti model participatornega proračuna in
              jih spodbuditi k vključitvi v projekt.
            </ParagraphL>
          </Content>
          <Content>
            <TitleM color={colors.white}>Opis</TitleM>
            <ParagraphL color={colors.white} weight="300">
              Občina Tolmin je v letu 2020 del proračunskih sredstev prvič namenila participatornemu
              proračunu. 20.000€ so namenili <b>mladinskim projektom</b>, ki jih lahko predlagajo in
              zanje glasujejo le mladi iz tolminske občine.
            </ParagraphL>
          </Content>
        </SectionIntro>

        <div className="section-wrapper">
          {/* Sticky Share and Mint buttons - Aways visible */}
          <StickyButtons>
            <MintButtons isColumn selected={selected} setSelected={setSelected} />
            <ShareButtons isColumn />
          </StickyButtons>

          {/* RichText Section - Conditionally visible */}
          <SectionRichText className="reduce-top-padding">
            <Content>
              {/* Example Title */}
              <h2>Celostna grafična podoba projekta</h2>

              {/* Example Paragraph */}
              <p>
                Za naročnika smo oblikovali celostno grafično podobo Mlade Pobude. Uporabili smo
                barve iz grba Občine Tolmin v bolj mladostnih odtenkih. Logotip ponazarja{' '}
                <b>vzpodbudo, pobudo mladih.</b>
              </p>

              {/* Example Image */}
              <img src={img2} alt="Image title" />

              {/* Example Title */}
              <h2>Promocijski videi</h2>

              {/* Example List */}
              <ul>
                <li>Pripravili smo tudi več promocijskih videov.</li>
                <li>
                  Prvi, daljši video je bil namenjen informiranju o Mladi Pobudi, v njem pa so
                  nastopali mladi iz delovne skupine.
                </li>
                <li>
                  Video na humoren in sproščen način predstavi vse ključne lastnosti projekta, da je
                  gledalcu po ogledu tega <b>1-minutnega videa jasno zakaj gre.</b>
                </li>
                <li>
                  Za tri krajše videe, smo poiskali <b>ambasadorje iz ciljne skupine.</b>
                </li>
                <li>Mladi so pozvali svoje sovrstnike, da oddajo pobude za mladinske projekte. </li>
                <li>
                  Na ta način smo projekt dodatno <b>približali mladim,</b> ki so v promocijskih
                  videih prepoznali svoje prijatelje in znance
                </li>
              </ul>
            </Content>
          </SectionRichText>

          {/* CounterImages Section - Conditionally visible */}
          <SectionCounterImage>
            <div className="image">
              <img src={img4} alt="Image title" />
            </div>
            <Square background={colors.purple} className="counter">
              <HeadlineL color={colors.white} font={theme.fontSecondary}>
                36000+
              </HeadlineL>
              <SubtitleM color={colors.white}>Oddanih glasov</SubtitleM>
            </Square>
          </SectionCounterImage>
          <SectionCounterImage className="reverse">
            <div className="image">
              <img src={img7} alt="Image title" />
            </div>
            <Square background={colors.red} className="counter">
              <HeadlineL color={colors.white} font={theme.fontSecondary}>
                42
              </HeadlineL>
              <SubtitleM color={colors.white}>Mladih pobud</SubtitleM>
            </Square>
          </SectionCounterImage>

          {/* RichText Section - Conditionally visible */}
          <SectionRichText>
            <Content>
              {/* Example Title */}
              <h2>Promocijski videi</h2>

              {/* Example Paragraph */}
              <p>
                Pripravili smo tudi več promocijskih videov. Prvi, daljši video je bil namenjen
                informiranju o Mladi Pobudi, v njem pa so nastopali mladi iz delovne skupine. Video
                na humoren in sproščen način predstavi vse ključne lastnosti projekta, da je
                gledalcu po ogledu tega <b>1-minutnega videa jasno zakaj gre.</b>
              </p>

              {/* Example Paragraph */}
              <p>
                Za tri krajše videe, smo poiskali <b>ambasadorje iz ciljne skupine</b>. Mladi so
                pozvali svoje sovrstnike, da oddajo pobude za mladinske projekte. Na ta način smo
                projekt dodatno <b>približali mladim</b>, ki so v promocijskih videih prepoznali
                svoje prijatelje in znance.
              </p>

              {/* Example Paragraph */}
              <p>
                Več o ozadju in namenu Mlade Pobude je v videu povedal župan Občine Tolmin, Uroš
                Brežan.
              </p>
            </Content>
          </SectionRichText>

          {/* CounterGrid Section - Conditionally visible */}
          <SectionCounterGrid>
            <div className="group">
              <Square background={colors.purple} className="counter">
                <TitleL color={colors.white} font={theme.fontSecondary}>
                  36000+
                </TitleL>
                <ParagraphL color={colors.white}>Oddanih glasov</ParagraphL>
              </Square>
              <Square background={colors.red} className="counter">
                <TitleL color={colors.white} font={theme.fontSecondary}>
                  42
                </TitleL>
                <ParagraphL color={colors.white}>Mladih pobud</ParagraphL>
              </Square>
            </div>
            <div className="group reverse">
              <Square background={colors.purple} className="counter">
                <TitleL color={colors.white} font={theme.fontSecondary}>
                  36000+
                </TitleL>
                <ParagraphL color={colors.white}>Oddanih glasov</ParagraphL>
              </Square>
              <Square background={colors.red} className="counter">
                <TitleL color={colors.white} font={theme.fontSecondary}>
                  42
                </TitleL>
                <ParagraphL color={colors.white}>Mladih pobud</ParagraphL>
              </Square>
            </div>
          </SectionCounterGrid>

          {/* FullImage Section - Conditionally visible */}
          <SectionFullImage>
            <img src={img3} alt="Image title" />
          </SectionFullImage>

          {/* RichText Section - Conditionally visible */}
          <SectionRichText>
            <Content>
              {/* Example Title */}
              <h2>Socialni mediji in promocija</h2>

              {/* Example Paragraph */}
              <p>
                Za glavna komunikacijska kanala smo izbrali družbeni omrežji Facebook in Instagram,
                saj so tu prisotni mladi med 15 in 30 let. Ves čas trajanja projekta smo skrbeli za
                komuniciranje preko teh dveh kanalov.
              </p>

              {/* Example Paragraph */}
              <p>
                S kreativnimi vsebinami, ki so nagovorile ciljno skupino in oglaševanjem na
                družbenih omrežjih smo dosegli:
              </p>

              {/* Example Paragraph */}
              <p>
                Več kot 36 000 ogledov video vsebin samo na Facebooku Več kot 7 000 ogledov spletne
                strani 42 prejetih Mladih Pobud (pobudo je oddal vsak trideseti mlad občan) Xxx
                mladih je glasovalo
              </p>

              {/* Example Video */}
              <iframe
                src="https://www.youtube.com/embed/ik8l561iYEw"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>

              {/* Example Title */}
              <h2>Promocijski videi</h2>

              {/* Example Paragraph */}
              <p>
                Pripravili smo tudi več promocijskih videov. Prvi, daljši video je bil namenjen
                informiranju o Mladi Pobudi, v njem pa so nastopali mladi iz delovne skupine. Video
                na humoren in sproščen način predstavi vse ključne lastnosti projekta, da je
                gledalcu po ogledu tega <b>1-minutnega videa jasno zakaj gre.</b>
              </p>

              {/* Example Paragraph */}
              <p>
                Za tri krajše videe, smo poiskali ambasadorje iz ciljne skupine. Mladi so pozvali
                svoje sovrstnike, da oddajo pobude za mladinske projekte. Na ta način smo projekt
                dodatno približali mladim, ki so v promocijskih videih prepoznali svoje prijatelje
                in znance.
              </p>
            </Content>
          </SectionRichText>

          {/* ImagesGrid Section - Conditionally visible */}
          <SectionImagesGrid>
            <div className="image">
              <img src={img4} alt="Image title" />
            </div>
            <div className="image">
              <img src={img5} alt="Image title" />
            </div>
            <div className="image">
              <img src={img6} alt="Image title" />
            </div>
            <div className="image">
              <img src={img7} alt="Image title" />
            </div>
            <div className="image">
              <img src={img8} alt="Image title" />
            </div>
            <div className="image">
              <img src={img9} alt="Image title" />
            </div>
          </SectionImagesGrid>
        </div>

        {/* Footer Section - Aways visible */}
        <SectionFooter>
          <ParagraphL>Kako se ti je zdel ta projekt?</ParagraphL>
          <div className="mint-buttons">
            <MintButtons selected={selected} setSelected={setSelected} />
          </div>
          <div className="share-buttons">
            <ShareButtons />
          </div>
          <div className="contact">
            <img src={imgTeamContact} alt="Contact Team" />
            <ParagraphS weight="300">
              Živjo, sem Romina. Ustanoviteljica Mint & Square agencije in kreativna direktorica.
              Želiš več informacij o tej objavi, ali pa stopiti v kontakt? Lahko mi napišeš kar na{' '}
              {''}
              <a href="https://www.linkedin.com/in/romina-gerbec/" target="_blank">
                LinkedIn
              </a>{' '}
              👌
            </ParagraphS>
          </div>
        </SectionFooter>

        <SectionNavigator>
          <Link to="/project/previous-project">
            <ParagraphL>← Prejšnji projekt </ParagraphL>
          </Link>
          <Link to="/project/next-project">
            <ParagraphL>Naslednji projekt →</ParagraphL>
          </Link>
        </SectionNavigator>
      </ProjectDetailsLayout>
    </>
  )
}
export default ProjectDetails
